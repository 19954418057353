<template>
  <div class="message-detail">
    <div v-if="$route.params.type !== 1" style="padding-top: 10px">
      <div
        :class="{
          status: true,
          wait_color: info.approvalStatus == 0 || info.approvalStatus == 4,
          refuse_color: info.approvalStatus == 3 || info.approvalStatus == 2,
          pass_color: info.approvalStatus == 1,
        }"
      >
        {{
          info.approvalStatus == 0
            ? "待处理"
            : info.approvalStatus == 1
            ? "已通过"
            : info.approvalStatus == 2
            ? "未通过"
            : info.approvalStatus == 3
            ? "课时已被调整"
            : info.approvalStatus == 4
            ? "已撤回"
            : ""
        }}
      </div>
      <div class="info">
        <ul>
          <li class="title">{{ info.groupName }}</li>
          <li>
            <span
              ><img src="../../../assets/images/personnel.png" alt=""
            /></span>
            {{ info.studentName }}
          </li>
          <!-- <li>
            <span><img src="../../../assets/images/clock.png" alt="" /></span>
            {{ info.startTime + "-" }}{{ info.endTime }}（当前第1节/共5节）
          </li> -->
          <li>
            <span></span>
            {{ info.date }} {{ info.week }}
          </li>
          <li class="approver">
            <p>审批人</p>
            <p>{{ info.approver || "排课班主任" }}</p>
          </li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li>
            <span>课时调整：</span
            ><span class="key">{{
              info.operationType == 1
                ? "请假并改期"
                : info.operationType == 2
                ? "请假"
                : "预约上课"
            }}</span>
          </li>
          <li>
            <span></span
            ><span class="key">{{
              info.operationType == 1 ? "将这节课改至其他时间上课" : ""
            }}</span>
          </li>
          <li>
            <span>原上课时间：</span
            ><span class="key">{{ info.oldDateTime }}</span>
          </li>
          <li>
            <span>新上课时间：</span
            ><span class="key">{{ info.newDateTime }}</span>
          </li>
          <li>
            <span>原因说明：</span>
            <div class="remarks">
              {{ info.reason }}
            </div>
          </li>
          <li class="line"></li>
          <li>
            <span>提交时间：</span
            ><span class="key">{{ info.createTime }}</span>
          </li>
        </ul>
      </div>

      <button
        class="revoke_btn"
        @click="revoke"
        v-if="
          info.approvalStatus != 3 &&
          info.approvalStatus != 1 &&
          info.approvalStatus != -1
        "
      >
        {{
          info.approvalStatus == 2 || info.approvalStatus == 4
            ? "重新提交"
            : "撤回"
        }}
      </button>
    </div>
    <div v-else>
      <div class="system_message">
        <p>{{ $route.params.title }}</p>
        <p>{{ $route.params.time }}</p>
        <div>
          <pre>{{ $route.params.content }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  modifyRecordDetail,
  modifyReSubmit2,
  modifyRecall,
} from "../../../api/mentor";
import { Dialog } from "vant";
export default {
  data() {
    return {
      value: "",
      type: 1,
      info: {},
    };
  },
  methods: {
    // checkBox
    checkChange(e) {
      console.log(e.target.checked, this.value);
    },
    // 撤销
    revoke() {
      if (this.info.approvalStatus == 2 || this.info.approvalStatus == 4) {
        Dialog.confirm({
          title: "提示",
          message: "是否重新提交申请",
        })
          .then(() => {
            this.modifyReSubmit2();
          })
          .catch(() => {
            // on cancel
          });
      } else if (this.info.approvalStatus == 0) {
        Dialog.confirm({
          title: "提示",
          message: "是否撤销申请",
        })
          .then(() => {
            this.modifyRecall();
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    async getInfo() {
      const res = await modifyRecordDetail(this.$route.params.id);
      this.info = res;
    },
    async modifyRecall() {
      const res = await modifyRecall(this.$route.params.id);
      if (res) {
        this.$router.go(-1);
      }
    },
    async modifyReSubmit2() {
      const res = await modifyReSubmit2({ id: this.$route.params.id });
      if (res) {
        this.$router.go(-1);
      }
    },
  },
  created() {
    if (this.$route.params.type != 1) {
      this.getInfo();
      document.title = "请假或调课";
    } else {
      document.title = "系统消息";
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '../../../common.styl';
@import '../../../theme.styl';

div {
  box-sizing: border-box;
}

.message-detail {
  .status {
    height: 44px;
    width: 100%;
    text-align: center;
    line-height: 44px;
    background: #fff;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
  }

  .pass_color {
    color: pass;
  }

  .refuse_color {
    color: refuse;
  }

  .wait_color {
    color: wait;
  }

  .info {
    // height: 192px;
    background-color: #fff;
    margin-top: 10px;

    ul {
      padding: 24px 0 0 0;

      li {
        line-height: 25px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        color: #858585;
        padding: 0 15px;
      }

      span {
        display: block;
        width: 16px;
        margin: auto 0;
        margin-right: 6px;
        position: relative;
        top: 2px;

        img {
          width: 16px;
          height: 16px;
          opacity: 0.5;
        }
      }

      .title {
        color: #0E0E0E;
        font-weight: 600;
        line-height: 30px;
        font-size: 17px;
      }

      .approver {
        display: flex;
        justify-content: space-between;
        height: 50px;
        line-height: 50px;
        border-top: 1px solid #E4E4E4;
        padding: 0;
        margin-left: 15px;
        color: #0E0E0E;
        margin-top: 12px;

        p:nth-child(2) {
          color: #858585;
          padding-right: 15px;
        }
      }
    }
  }

  .content {
    padding-bottom: 24px;

    ul {
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      padding: 11px 0;
      margin: 10px 0 0 0;
      background-color: #fff;

      li {
        line-height: 25px;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        display: flex;
        justify-content: space-between;
        padding: 11px 15px;

        .key {
          font-size: 14px;
          color: #858585;
        }

        input {
          margin: auto 0;
        }

        .remarks {
          width: 214px;
          word-wrap: normal;
          color: #858585;
          text-align: right;
        }
      }

      li:nth-child(1) {
        padding-bottom: 0;
      }

      li:nth-child(2) {
        padding-top: 0;
        line-height: 14px;
      }

      .line {
        height: 1px;
        background-color: #E4E4E4;
        padding: 0;
        margin: 11px 0 11px 15px;
      }
    }
  }

  .revoke_btn {
    width: 345px;
    height: 45px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: refuse;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    margin-top: 84px;
    border: 1px solid #EEEEEE;
  }

  .system_message {
    padding: 24px 15px;
    background-color: #fff;
    margin-top: 1px;

    p {
      font-weight: 700;
      font-size: 16px;
      color: #0E0E0E;
    }

    p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      color: #AAAAAA;
      margin: 7px 0 13px 0;
    }

    pre {
      font-weight: 400;
      word-break: break-word;
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
      color: #0E0E0E;
      margin-top: 6px;
    }
  }
}
</style>